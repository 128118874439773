import React from "react";
import "./App.css";
import { HashRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import Kontakt from "./pages/Kontakt";
import O_nas from "./pages/O_nas";
import Uslugi from "./pages/Uslugi";
import Floppa from "./pages/Floppa";

function App() {
  return (
    <div className="app bg-slate-200 dark:bg-slate-900 min-h-screen w-full transition-colors duration-500 ease-in-out">
      <HashRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/o_nas" element={<O_nas />} />
          <Route path="/uslugi" element={<Uslugi />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/kontakt" element={<Kontakt />} />
          <Route path="/*" element={<Floppa />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;

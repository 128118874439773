import Navbar from "../components/Navbar";
import Posts from "../components/Posts";

export default function Blog() {
  return (
    <>
      <Navbar />
      <Posts />
    </>
  );
}

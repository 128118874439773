import React from "react";
import BlogPost from "./Postmaker";
import blogPosts from "../text/blog/posty.json";
import blog_text from "../text/blog/blog.json";

const BlogPage = () => {
  return (
    <div className="container mx-auto py-12">
      <h1 className="text-4xl font-bold text-center text-gray-800 dark:text-white mb-2 pt-2">
        <span className="text-indigo-600 dark:text-indigo-400">
          {blog_text.firma}{" "}
        </span>
        {blog_text.tytul}
      </h1>
      <div className="font-semibold text-center text-xl text-gray-600 dark:text-gray-300 max-w-xl leading-relaxed mx-auto">
        {blog_text.zawartosc}
      </div>
      {blogPosts.map((post, index) => (
        <BlogPost
          key={index}
          title={post.tytul}
          content={post.zawartosc}
          date={post.data}
        />
      ))}
    </div>
  );
};

export default BlogPage;

import Navbar from "../components/Navbar";
import ContactForm from "../components/ContactForm";

export default function Kontakt() {
  return (
    <>
      <Navbar />
      <ContactForm />
    </>
  );
}

import React from "react";
import "./Hero.css";
import { Button } from "./Button";
import home_text from "../text/home/home.json";
import { Link } from "react-router-dom";

function Hero() {
  return (
    <section className="hero-main mx-auto max-w-screen-xl pb-12 px-4 items-center lg:flex md:px-8">
      <div className="space-y-4 flex-1 sm:text-center lg:text-left">
        <h1 className="text-gray-800 dark:text-white font-bold text-4xl xl:text-5xl">
          {home_text.tytul}
          <span className="text-indigo-600 dark:text-indigo-400">
            {" "}
            {home_text.firma}
          </span>
        </h1>
        <p className="text-gray-600 dark:text-gray-300 max-w-xl leading-relaxed sm:mx-auto lg:ml-0">
          {home_text.podtytul}
        </p>
        <div className="pt-10 items-center justify-center space-y-3 sm:space-x-6 sm:space-y-0 sm:flex lg:justify-start">
          {Button && (
            <Button onclick={"kontakt"} buttonStyle={""}>
              {home_text.przycisk1}
            </Button>
          )}
          {Button && (
            <Button onclick={"o_nas"} buttonStyle={"btn--secondary"}>
              {home_text.przycisk2}
            </Button>
          )}
        </div>
      </div>
      <div className="flex-1 text-center mt-7 lg:mt-0 lg:ml-3">
        <Link to="/kontakt">
          <img
            src="./images/hero.png"
            className="w-full mx-auto sm:w-10/12 lg:w-full transform transition-transform hover:scale-110"
            alt="Hero Image"
          />
        </Link>
      </div>
    </section>
  );
}

export default Hero;

// BlogPost.js
import React, { useState } from "react";

const BlogPost = ({ title, content, date }) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="max-w-2xl mx-auto bg-white dark:bg-gray-800 shadow-md rounded-md p-8 mt-8 break-words">
      <h2 className="text-2xl font-bold text-gray-800 dark:text-white mb-4">
        {title}
      </h2>
      <p className="text-gray-600 dark:text-gray-300 mb-2">{date}</p>
      <p className="text-gray-700 dark:text-gray-400">
        {content.length > 100 && !showModal
          ? content.substring(0, 100) + "..."
          : content}
      </p>
      <button
        onClick={openModal}
        className="text-indigo-600 dark:text-indigo-400 hover:underline focus:outline-none"
      >
        Czytaj więcej
      </button>

      {showModal && (
        <div className="fixed inset-0 z-10 overflow-y-auto bg-gray-800 bg-opacity-70 flex items-center justify-center break-words">
          <div className="bg-white dark:bg-gray-800 rounded-md p-8 max-w-2xl dark:border dark:border-solid dark:border-slate-200">
            <h2 className="text-2xl font-bold text-gray-800 dark:text-white mb-4">
              {title}
            </h2>
            <p className="text-gray-600 dark:text-gray-300 mb-2">{date}</p>
            <p className="text-gray-700 dark:text-gray-400">{content}</p>
            <button
              onClick={closeModal}
              className="mt-4 text-indigo-600 dark:text-indigo-400 hover:underline focus:outline-none"
            >
              Wyjdź
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogPost;

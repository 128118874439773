import Navbar from "../components/Navbar";

export default function Floppa() {
  return (
    <>
      <Navbar />
      <div className="flex justify-center content-center flex-wrap overflow-hidden">
        <h2 className="text-center text-gray-800 dark:text-white font-bold text-5xl xl:text-6xl">
          Oops! Something went wrong.
        </h2>
      </div>
    </>
  );
}

import React from "react";
import { Button } from "./Button";
import onas from "../text/o_nas/onas.json";
import { Link } from "react-router-dom";

function About() {
  return (
    <section className="hero-main mx-auto max-w-screen-xl pb-12 px-4 content-center gap-20 items-center lg:flex lg:flex-wrap md:px-8">
      <div className="space-y-4 text-center lg:w-dvw">
        <h1 className="text-gray-800 dark:text-white font-bold text-5xl xl:text-6xl pb-8">
          {onas.tytul}
          <span className="text-indigo-600 dark:text-indigo-400">
            {" "}
            {onas.firma}
          </span>
        </h1>
      </div>
      <div className="space-y-4 flex-1 sm:text-center lg:text-left">
        <p className="font-semibold text-xl text-gray-600 dark:text-gray-300 max-w-xl leading-relaxed sm:mx-auto lg:ml-0">
          <span className="text-indigo-600 dark:text-indigo-400">
            {onas.podtytul_firma}
          </span>{" "}
          {onas.podtytul}
        </p>
        <div className="pt-10 items-center justify-center space-y-3 sm:space-x-6 sm:space-y-0 sm:flex lg:justify-start">
          {Button && (
            <Button onclick={"kontakt"} buttonStyle={""}>
              {onas.przycisk1}
            </Button>
          )}
          {Button && (
            <Button onclick={"uslugi"} buttonStyle={"btn--secondary"}>
              {onas.przycisk2}
            </Button>
          )}
        </div>
      </div>
      <div className="flex-1 text-center mt-7 lg:mt-0 lg:ml-3 relative overflow-hidden">
        <Link to="/kontakt">
          <img
            src="./images/about.png"
            className="w-full mx-auto sm:w-10/12 lg:w-full transform transition-transform hover:scale-110 -translate-y-8"
            alt="About Image"
          />
        </Link>
      </div>
    </section>
  );
}

export default About;

import React from "react";
import { Link } from "react-router-dom";

const STYLES = ["btn--primary", "btn--secondary"];
const styles = [
  "px-7 py-3 w-full font-semibold transition-colors duration-200 ease-in-out bg-gray-100 hover:bg-gray-300 hover:text-black text-gray-800 text-center rounded-md shadow-md block sm:w-auto",
  "px-7 py-3 w-full font-semibold transition-colors duration-200 ease-in-out bg-blue-500 hover:bg-blue-400 text-white dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-gray-200 text-center rounded-md block sm:w-auto",
];

export const Button = ({ children, onclick, buttonStyle, addstyle }) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle == STYLES[0]
      ? styles[0]
      : styles[1]
    : styles[0];

  return (
    <Link to={`/${onclick}`} className={`${checkButtonStyle} ${addstyle}`}>
      {children}
    </Link>
  );
};

import Navbar from "../components/Navbar";
import About from "../components/About";

export default function O_nas() {
  return (
    <>
      <Navbar />
      <About />
    </>
  );
}

import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import form_text from "../text/kontakt/form.json";
import kontakt_text from "../text/kontakt/kontakt.json";

const service_id = "service_26qdcjh";
const template_id = "template_gp6jphv";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    emailjs
      .send(service_id, template_id, formData, "bCgAMS1Q-2aFL5t8c")
      .then((res) => {
        console.log("Form submitted successfully:", formData);
        setFormData({
          email: "",
          subject: "",
          message: "",
        });
        alert("Formularz został wysłany pomyślnie!");
      })
      .catch((err) => {
        console.error("Form submission failed: ", err);
        alert("Wystąpił błąd podczas wysyłania formularza: ", err);
      });
  };

  const lines = kontakt_text.podtytul.split("\n");

  return (
    <section>
      <div className="py-8 lg:py-16 px-4 mx-auto">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
          {kontakt_text.tytul}
        </h2>
        <p className="mb-8 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
          {lines.map((line, index) => (
            <p key={index}>{line}</p>
          ))}
        </p>
        <div className="flex flex-wrap max-w-screen-xl gap-10 align-middle justify-center m-auto">
          <form onSubmit={handleSubmit} className="space-y-8 max-w-lg w-screen">
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                {form_text.mail}
              </label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder={form_text.mail_placeholder}
                required
              />
            </div>
            <div>
              <label
                htmlFor="subject"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                {form_text.temat}
              </label>
              <input
                type="text"
                id="subject"
                value={formData.subject}
                onChange={handleChange}
                className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder={form_text.temat_placeholder}
                required
              />
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
              >
                {form_text.mess}
              </label>
              <textarea
                id="message"
                rows="6"
                value={formData.message}
                onChange={handleChange}
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder={form_text.mess_placeholder}
              ></textarea>
            </div>
            <button
              type="submit"
              className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              {form_text.przycisk}
            </button>
          </form>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1253.4524246739236!2d19.431811618385314!3d51.07330650197109!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4719fffdbb601b0d%3A0x71159b3519c7b0ce!2sFASTBIT%20-%20sklep%20i%20serwis%20komputer%C3%B3w!5e0!3m2!1spl!2spl!4v1704550996194!5m2!1spl!2spl"
            className="border-0 max-w-lg w-screen min-h-96"
            allowfullscreen
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <p className="text-center text-gray-600 dark:text-gray-300 sm:text-xl">
        {kontakt_text.kontakt_text}
      </p>
      <div className="text-center font-light p-4 text-gray-500 dark:text-gray-400 flex gap-10 justify-center">
        <span>{kontakt_text.adres}</span>
        <span>{kontakt_text.telefon}</span>
        <span>{kontakt_text.mail}</span>
      </div>
    </section>
  );
};

export default ContactForm;

import React from "react";
import { Button } from "./Button";
import services from "../text/uslugi/karty.json";
import uslugi from "../text/uslugi/uslugi.json";

function Services() {
  return (
    <section className="mx-auto max-w-screen-xl pb-12 px-4 content-center gap-20 items-center lg:flex lg:flex-wrap md:px-8">
      <div className="space-y-4 text-center lg:w-dvw">
        <h1 className="text-gray-800 dark:text-white font-bold text-5xl xl:text-6xl pb-8">
          {uslugi.tytul}
          <span className="text-indigo-600 dark:text-indigo-400">
            {" "}
            {uslugi.firma}
          </span>
        </h1>
      </div>
      <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 xl:grid-cols-4">
        {services.map((service, index) => (
          <div
            key={index}
            className="sm:text-center lg:text-left p-6 border border-gray-300 dark:border-gray-700 rounded-lg transition transform hover:scale-105 hover:shadow-lg bg-white dark:bg-gray-800 bg-opacity-70 flex flex-col justify-between"
          >
            <div>
              <h2 className="text-xl font-semibold mb-2 text-indigo-600 dark:text-indigo-400">
                {service.title}
              </h2>
              <p className="text-gray-600 dark:text-gray-300">
                {service.description}
              </p>
            </div>
            <img
              src={service.imageUrl}
              alt={`Service ${index + 1}`}
              className="mt-4 rounded-md shadow-md object-cover object-cente sm:w-1/2 lg:w-full mx-auto"
            />
          </div>
        ))}
      </div>
      <div className="flex-1 text-center mt-7 lg:mt-0 lg:ml-3 relative overflow-hidden">
        <div className="pt-2 items-center justify-center space-y-3 sm:space-x-6 sm:space-y-0 sm:flex lg:justify-center">
          {Button && (
            <Button onclick={"kontakt"} buttonStyle={""}>
              {uslugi.przycisk}
            </Button>
          )}
        </div>
      </div>
    </section>
  );
}

export default Services;

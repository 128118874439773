import Navbar from "../components/Navbar";
import Services from "../components/Services";

export default function Uslugi() {
  return (
    <>
      <Navbar />
      <Services />
    </>
  );
}
